<template>
  <div class="content">
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">
          Sincronizar dados com o servidor
        </h4>
        <div class="ms-auto">
          <router-link
            to="/accreditations/home"
            class="btn btn-outline-dark"
            title="Voltar Para à Tela Inicial"
          >
            Voltar Para à Tela Inicial
          </router-link>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Dados</th>
              <th class="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Empresas</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-outline-secondary rounded-pill btn-icon"
                  title="Sincronizar"
                  :disabled="lineTableClicked === 'syncCompanies'"
                  @click="syncs('syncCompanies')"
                >
                  <span v-if="lineTableClicked === 'syncCompanies'">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span v-else>
                    <i class="ph-arrows-clockwise"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td>Unidades</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-outline-secondary rounded-pill btn-icon"
                  title="Sincronizar"
                  :disabled="lineTableClicked === 'syncUnities'"
                  @click="syncs('syncUnities')"
                >
                  <span v-if="lineTableClicked === 'syncUnities'">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span v-else>
                    <i class="ph-arrows-clockwise"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td>Setores</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-outline-secondary rounded-pill btn-icon"
                  title="Sincronizar"
                  :disabled="lineTableClicked === 'syncDepartments'"
                  @click="syncs('syncDepartments')"
                >
                  <span v-if="lineTableClicked === 'syncDepartments'">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span v-else>
                    <i class="ph-arrows-clockwise"></i>
                  </span>
                </button>
              </td>
            </tr>
            <tr>
              <td>Cargos</td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-outline-secondary rounded-pill btn-icon"
                  title="Sincronizar"
                  :disabled="lineTableClicked === 'syncPositions'"
                  @click="syncs('syncPositions')"
                >
                  <span v-if="lineTableClicked === 'syncPositions'">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span v-else>
                    <i class="ph-arrows-clockwise"></i>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Syncs',

  data() {
    return {
      lineTableClicked: '',
    }
  },

  methods: {
    async syncs(dispatch) {
      try {
        this.lineTableClicked = dispatch

        const response = await this.$store.dispatch(dispatch)
        this.$root.$refs.notification.success(response.message)

        this.lineTableClicked = ''
      } catch (error) {
        this.lineTableClicked = ''
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
